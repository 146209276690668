exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-register-complete-signup-tsx": () => import("./../../../src/pages/register/complete-signup.tsx" /* webpackChunkName: "component---src-pages-register-complete-signup-tsx" */),
  "component---src-pages-register-continue-subscription-tsx": () => import("./../../../src/pages/register/continue-subscription.tsx" /* webpackChunkName: "component---src-pages-register-continue-subscription-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog-article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-course-group-tsx": () => import("./../../../src/templates/course-group.tsx" /* webpackChunkName: "component---src-templates-course-group-tsx" */),
  "component---src-templates-course-location-tsx": () => import("./../../../src/templates/course-location.tsx" /* webpackChunkName: "component---src-templates-course-location-tsx" */),
  "component---src-templates-course-topic-tsx": () => import("./../../../src/templates/course-topic.tsx" /* webpackChunkName: "component---src-templates-course-topic-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-group-booking-tsx": () => import("./../../../src/templates/group-booking.tsx" /* webpackChunkName: "component---src-templates-group-booking-tsx" */),
  "component---src-templates-legal-page-tsx": () => import("./../../../src/templates/legal-page.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-location-group-tsx": () => import("./../../../src/templates/location-group.tsx" /* webpackChunkName: "component---src-templates-location-group-tsx" */),
  "component---src-templates-tutor-tsx": () => import("./../../../src/templates/tutor.tsx" /* webpackChunkName: "component---src-templates-tutor-tsx" */)
}

